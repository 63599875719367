var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"crew-member-certificates"},[_c('v-container',{staticClass:"container-lg"},[(_vm.agencyAdminRole && !_vm.model.active)?_c('v-card',[_c('v-card-title',[_c('crew-member-profile-picture',{attrs:{"photoId":_vm.getCrewProfilePicture,"crewMemberId":_vm.$route.params.crewMemberId},on:{"update-profile":_vm.getCrewMember}}),_c('div',{staticClass:"ml-4"},[_vm._v("Crew Member - "+_vm._s(_vm.model.fullname))])],1)],1):_vm._e(),(_vm.superAdminRole)?_c('v-switch',{attrs:{"label":_vm.showArchived ? 'Show Unarchived' : 'Show Archived'},model:{value:(_vm.showArchived),callback:function ($$v) {_vm.showArchived=$$v},expression:"showArchived"}}):_vm._e(),_c('v-card',{staticClass:"mt-2"},[_c('v-card-title',{staticClass:"pt-0"},[_vm._v(" Land Worker Certificates "),_c('v-spacer'),_c('base-input',{staticClass:"mt-6 mx-2",attrs:{"dense":"","prepend-icon":"mdi-magnify","placeholder":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(!_vm.agencyAdminRole)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onCreateCertificate}},[_vm._v("Create")]):_vm._e()],1),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.filteredCertificates,"headers":_vm.headers,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.startDate))+" ")]}},{key:"item.expiryDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.expiryDate))+" ")]}},{key:"item.createdUtc",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.createdUtc))+" ")]}},{key:"item.dealtWith",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":_vm.agencyAdminRole},on:{"click":function($event){return _vm.updateCertificateDealtWith(item)}},model:{value:(item.dealtWith),callback:function ($$v) {_vm.$set(item, "dealtWith", $$v)},expression:"item.dealtWith"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","min-width":"120"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[(!_vm.agencyAdminRole)?_c('v-list-item',{on:{"click":function($event){return _vm.onEditCertificate(item)}}},[_c('v-list-item-title',[_vm._v("Edit")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.onDownloadCertificate(item)}}},[_c('v-list-item-title',[_vm._v("Download")])],1),(!item.archived && !_vm.agencyAdminRole)?_c('v-list-item',{on:{"click":function($event){return _vm.archiveCertificate(item)}}},[_c('v-list-item-title',[_vm._v("Archive")])],1):_vm._e(),(item.archived && !_vm.agencyAdminRole)?_c('v-list-item',{on:{"click":function($event){return _vm.unarchiveCertificate(item)}}},[_c('v-list-item-title',[_vm._v("Unarchive")])],1):_vm._e(),(!_vm.agencyAdminRole)?_c('v-list-item',{on:{"click":function($event){return _vm.onDeleteCertificate(item)}}},[_c('v-list-item-title',[_vm._v("Delete")])],1):_vm._e()],1)],1)]}}])})],1)],1)],1),_c('crew-member-certificate-create-dialog',{attrs:{"dialog":_vm.createDialog,"crewMemberId":_vm.crewMemberId,"discriminator":1},on:{"update:dialog":function($event){_vm.createDialog=$event},"certificate-created":_vm.getCertificates}}),_c('crew-member-certificate-update-dialog',{attrs:{"dialog":_vm.editDialog,"crewMemberCertificateId":_vm.crewMemberCertificateId},on:{"update:dialog":function($event){_vm.editDialog=$event},"certificate-updated":_vm.getCertificates}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }